import type { ActionContext, ActionTree, GetterTree, MutationTree } from 'vuex';
import type { RootState } from '@/store/store';
export const enum MENU_STATUS {
    EXPENDED,
    COLLAPSED,
}
export const LOCAL_STORAGE_JWT_KEY = 'token';

const EVENT_MENU_COLLAPSE = 'menu_collapse';
const EVENT_MENU_EXPEND = 'menu_expend';

export const ACTION_MENU_COLLAPSE = 'menuCollapse';
export const ACTION_MENU_EXPEND = 'menuExpend';
export const ACTION_MENU_TOGGLE = 'menuToggle';

const MUTATION_MENU_COLLASPE = 'menu_collapse';
const MUTATION_MENU_EXPEND = 'menu_expend';

export const GETTER_MENU_STATE = 'menuState';

interface NavigationState {
    menu: MENU_STATUS;
}

const state: NavigationState = {
    menu: MENU_STATUS.COLLAPSED,
};

const getters: GetterTree<NavigationState, RootState> = {
    [GETTER_MENU_STATE]: (s) => s.menu,
};

const actions: ActionTree<NavigationState, RootState> = {
    [ACTION_MENU_COLLAPSE]({ commit }: ActionContext<NavigationState, RootState>) {
        commit(MUTATION_MENU_COLLASPE);
    },
    [ACTION_MENU_EXPEND]({ commit }: ActionContext<NavigationState, RootState>) {
        commit(MUTATION_MENU_EXPEND);
    },
    [ACTION_MENU_TOGGLE]({ commit, state: s }: ActionContext<NavigationState, RootState>) {
        if (s.menu === MENU_STATUS.COLLAPSED) {
            commit(MUTATION_MENU_EXPEND);
        } else {
            commit(MUTATION_MENU_COLLASPE);
        }
    },
};

const mutations: MutationTree<NavigationState> = {
    [MUTATION_MENU_COLLASPE](s) {
        s.menu = MENU_STATUS.COLLAPSED;
    },
    [MUTATION_MENU_EXPEND](s) {
        s.menu = MENU_STATUS.EXPENDED;
    },
};

export default {
    state,
    getters,
    actions,
    mutations,
};
