import * as VueRouter from 'vue-router';
import { ProductEnum } from '@/schema/product';
import store from '@/store/store';
import { ACTION_INITIALIZE_AUTH, GETTER_IS_AUTHENTICATED, GETTER_USER } from '@/store/modules/auth';
import { profileSchema, RoleEnum } from '@/schema/profile';
import z from 'zod';
import { ACTION_MENU_COLLAPSE } from '@/store/modules/navigation';

declare module 'vue-router' {
    interface RouteMeta {
        requiresProducts?: ProductEnum[];
        requiresAuth: boolean;
        title?: string;
    }
}

export const router = VueRouter.createRouter({
    history: VueRouter.createWebHashHistory(),
    routes: [
        { path: '/', name: 'public', component: () => import('@/views/Public.vue'), meta: { requiresAuth: false } },
        { path: '/legals', name: 'legals', component: () => import('@/views/Legals.vue'), meta: { requiresAuth: false, title: 'Mentions légales' } },
        { path: '/cguv', name: 'cguv', component: () => import('@/views/CGUV.vue'), meta: { requiresAuth: false, title: 'CGV/CGU' } },
        { path: '/confidentiality', name: 'confidentiality', component: () => import('@/views/Confidentiality.vue'), meta: { requiresAuth: false, title: 'Politique de confidentialité' } },
        { path: '/inscription/:token', name: 'register-password', component: () => import('@/views/RegisterPassword.vue'), meta: { requiresAuth: false, title: 'Définir mon mot de passe' } },
        { path: '/payment_success', name: 'payment_success', component: () => import('@/views/PaymentSuccess.vue'), meta: { requiresAuth: true, title: 'Confirmation de paiement' } },
        {
            path: '/mot-de-passe-oublie/:token',
            name: 'ask-reset-password',
            component: () => import('@/views/ResetPassword.vue'),
            meta: { requiresAuth: false, title: 'Réinitialiser mon mot de passe' },
        },
        { path: '/mot-de-passe-oublie', name: 'reset-password', component: () => import('@/views/AskResetPassword.vue'), meta: { requiresAuth: false, title: 'Réinitialiser mon mot de passe' } },
        { path: '/choix-abonnement', name: 'subscription-choice', component: () => import('@/views/SubscriptionChoice.vue'), meta: { requiresAuth: true, title: 'Choisir mon abonnement' } },
        {
            path: '/selection',
            name: 'product_selection',
            component: () => import('@/views/Selection.vue'),
            meta: { requiresAuth: true, title: 'Solutions' },
        },
        { path: '/signin', name: 'signin', component: () => import('@/views/Signin.vue'), meta: { requiresAuth: false } },
        {
            path: '/caudimmo',
            name: 'caudimmo',
            component: () => import('@/views/caudimmo/Caudimmo.vue'),
            meta: { requiresProducts: [ProductEnum.CAUDIMMO], requiresAuth: true, title: "Evaluer un local d'activité" },
        },
        {
            path: '/caudimmo/detail/:id',
            name: 'caudimmoDetail',
            props: (route) => ({ id: Number(route.params.id) }),
            component: () => import('@/views/caudimmo/Detail.vue'),
            meta: { requiresProducts: [ProductEnum.CAUDIMMO], requiresAuth: true },
        },
        {
            path: '/caudimmo/rapports',
            name: 'caudimmoRapports',
            props: () => ({ type: 2 }),
            component: () => import('@/views/rapport/Rapports.vue'),
            meta: { requiresProducts: [ProductEnum.CAUDIMMO], requiresAuth: true },
        },
        {
            path: '/caudimmo/rapport/:id',
            name: 'caudimmoRapportDetail',
            props: (route) => ({ id: Number(route.params.id), type: 'caudimmo' }),
            component: () => import('@/views/rapport/RapportDetail.vue'),
            meta: { requiresProducts: [ProductEnum.CAUDIMMO], requiresAuth: true },
        },
        {
            path: '/caudimmo/rapportPrint/:id',
            name: 'caudimmoRapportPrint',
            props: (route) => ({ id: Number(route.params.id) }),
            component: () => import('@/views/rapport/RapportPrint.vue'),
            meta: { requiresAuth: true },
        },
        {
            path: '/caudit',
            name: 'caudit',
            component: () => import('@/views/caudit/Caudit.vue'),
            meta: { requiresProducts: [ProductEnum.CAUDIT], requiresAuth: true, title: "Evaluer les titres d'une société" },
        },
        {
            path: '/caudit/detail/:id',
            name: 'cauditDetail',
            props: (route) => ({ id: Number(route.params.id) }),
            component: () => import('@/views/caudit/Detail.vue'),
            meta: { requiresProducts: [ProductEnum.CAUDIT], requiresAuth: true },
        },
        {
            path: '/caudit/rapports',
            name: 'cauditRapports',
            props: () => ({ type: 1 }),
            component: () => import('@/views/rapport/Rapports.vue'),
            meta: { requiresProducts: [ProductEnum.CAUDIT], requiresAuth: true },
        },
        {
            path: '/caudit/rapport/:id',
            name: 'cauditRapportDetail',
            props: (route) => ({ id: Number(route.params.id), type: 'caudit' }),
            component: () => import('@/views/rapport/RapportDetail.vue'),
            meta: { requiresProducts: [ProductEnum.CAUDIT], requiresAuth: true },
        },
        {
            path: '/caudit/rapportPrint/:id',
            name: 'cauditRapportPrint',
            props: (route) => ({ id: Number(route.params.id) }),
            component: () => import('@/views/rapport/RapportPrint.vue'),
        },
        {
            path: '/admin/users/:id',
            name: 'adminUserView',
            component: () => import('@/views/admin/UserView.vue'),
            props: (route) => ({ id: Number(route.params.id) }),
            meta: { requiresAuth: true },
        },
        {
            path: '/admin/users',
            name: 'adminUserList',
            component: () => import('@/views/admin/UserList.vue'),
            meta: { requiresAuth: true },
        },

        { path: '/:pathMatch(.*)*', redirect: '/' },
    ],
});

router.beforeEach(async (to, from, next) => {
    void store.dispatch(ACTION_MENU_COLLAPSE);
    document.title = to.meta?.title ? `Caudia - ${to.meta?.title}` : 'Caudia';

    await store.dispatch(ACTION_MENU_COLLAPSE);
    let isAuthenticated = store.getters[GETTER_IS_AUTHENTICATED] as boolean;
    let user = store.getters[GETTER_USER] as z.infer<typeof profileSchema> | null;

    if (!isAuthenticated || !user) {
        await store.dispatch(ACTION_INITIALIZE_AUTH);
        isAuthenticated = store.getters[GETTER_IS_AUTHENTICATED] as boolean;
        user = store.getters[GETTER_USER] as z.infer<typeof profileSchema> | null;
    }

    const requireProducts: ProductEnum[] | undefined = to.meta?.requiresProducts;

    const requireAuthenticatedUser = to.meta?.requiresAuth || requireProducts?.length;

    if (!requireAuthenticatedUser) {
        next();
        return;
    }

    if (!isAuthenticated || !user) {
        //TODO make notification
        console.log('Router detect user not authenticated!');
        next({ name: 'signin' });
        return;
    }

    if (user.roles.includes(RoleEnum.ADMIN)) {
        next();
        return;
    }

    if (user.product_access.length === 0 && to.name !== 'subscription-choice') {
        next({ name: 'subscription-choice' });
        return;
    }

    if (requireProducts && !requireProducts?.some((e) => user.product_access.includes(e))) {
        next({ name: 'public' });
        return;
    }

    next();
});

router.onError((error, to) => {
    if (error instanceof Error && error.message.includes('Failed to fetch dynamically imported module')) {
        // eslint-disable-next-line no-console
        console.warn(error);
        window.location.hash = to.fullPath;
        window.location.reload();
    }
});
