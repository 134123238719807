import 'intl-relative-time-format';
import '@formatjs/intl-getcanonicallocales/polyfill';
import Toast, { type PluginOptions, POSITION } from 'vue-toastification';

import { createApp } from 'vue';
import '@/helpers';
import Main from '@/Main.vue';
import { router } from '@/router';
import '@/main.scss';
import 'vue-toastification/dist/index.css';
import 'ag-grid-community/styles/ag-grid.css'; // Mandatory
import 'ag-grid-community/styles/ag-theme-balham.css'; // Optional
import PrimeVue from 'primevue/config';
import Aura from '@primevue/themes/aura';

/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import {
    faCheck,
    faChevronDown,
    faChevronUp,
    faCircleInfo,
    faImage,
    faPlus,
    faSpinner,
    faTable,
    faFileCirclePlus,
    faXmark,
    faEye,
    faFilePen,
    faTrash,
    faCopy,
    faCircleChevronDown,
    faCircleChevronUp,
    faMagnifyingGlass,
    faFileArrowDown,
    faEyeSlash,
    faWindowMaximize,
    faWindowMinimize,
    faFloppyDisk,
    faUserSlash,
    faListCheck,
    faBars,
    faGear,
    faArrowLeft,
    faFilePdf,
    faDownload,
    faSquare,
    faPrint,
    faCircleNotch,
} from '@fortawesome/free-solid-svg-icons';
import { faCircleUser, faClipboard } from '@fortawesome/free-regular-svg-icons';
import store from '@/store/store';
import { faHouse } from '@fortawesome/free-solid-svg-icons/faHouse';
import { faCompass } from '@fortawesome/free-regular-svg-icons/faCompass';
import { faSliders } from '@fortawesome/free-solid-svg-icons/faSliders';
import { faLinkedinIn } from '@fortawesome/free-brands-svg-icons';
import { faRotate } from '@fortawesome/free-solid-svg-icons/faRotate';

library.add([
    faChevronDown,
    faChevronUp,
    faCheck,
    faCircleInfo,
    faClipboard,
    faTable,
    faImage,
    faPlus,
    faSpinner,
    faFileCirclePlus,
    faXmark,
    faEye,
    faEyeSlash,
    faFilePen,
    faTrash,
    faCopy,
    faCircleChevronDown,
    faCircleChevronUp,
    faMagnifyingGlass,
    faFileArrowDown,
    faWindowMaximize,
    faWindowMinimize,
    faFloppyDisk,
    faHouse,
    faCompass,
    faUserSlash,
    faListCheck,
    faBars,
    faGear,
    faSliders,
    faCircleUser,
    faLinkedinIn,
    faRotate,
    faArrowLeft,
    faFilePdf,
    faDownload,
    faSquare,
    faPrint,
    faCircleNotch,
]);
const toastOptions: PluginOptions = {
    position: POSITION.TOP_CENTER,
    pauseOnFocusLoss: false,
};

const app = createApp(Main)
    .component('fa', FontAwesomeIcon)
    .use(Toast, toastOptions)
    .use(router)
    .use(store)
    .use(PrimeVue, {
        theme: {
            preset: Aura,
            options: {
                darkModeSelector: '.fake-dark-selector', // trying to also force a non-usage of the dark mode
            },
        },
    });
app.mount('#app');
