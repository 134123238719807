import { currencyFormater, numberFormater } from '@/helpers';
import { z } from 'zod';
import { titreSchema } from '@/schema/caudit';

export const defaultCauditFieldsCfg = (
    localStorage.getItem('caudit_fields') ??
    'dateDepot,nombreTotalPartsVendues,montantTotalVente,prixVenteUnePartSociale,nombrePartsExistantesTotalSocieteVendue,valeurCapitauxPropres,valeurEntreprise,multipleChiffreAffaires,denomination,siren,categorieJuridique,adresse,activite,nafId,dateCreationSociete,capitalSocialSocieteVendu,chiffreAffaires,ebe,ebeApresIs,resultatExploitation,resultatExploitationApresIs,resultatNet,capitauxPropres,endettementNet,etablissementOrigineFonds,multipleFondsChiffreAffaires'
).split(',');

export function asPrct(value: number, p?: number) {
    const div = p ?? 1;
    return `${Math.round((value / div) * 100)}%`;
}

export function formatField(format: string, field: keyof z.infer<typeof titreSchema>, titre: z.infer<typeof titreSchema> | undefined) {
    if (!titre) {
        return 'N/A';
    }
    switch (format) {
        case 'currency':
            return currencyFormater((titre[field] ?? 0) as number);
        case 'number':
            return numberFormater((titre[field] ?? 0) as number);
        case 'date':
            return (titre[field] as Date)?.toLocaleDateString('fr-FR', { timeZone: 'Europe/Paris' }) ?? '';
        case 'naf':
            return titre.naf ? `${titre.naf.code} - ${titre.naf.label}` : 'N/A';
        case 'catjur':
            return titre.catJur?.label ?? 'N/A';
        case 'address':
            return titre.adresse ? `${titre.adresse}` : 'N/A';
        default:
            return String(titre[field]) ?? 'N/A';
    }
}

export const cauditDetailsFieldList = {
    cession: [
        { label: 'Date', value: 'dateDepot', format: 'date', available: [1, 2], checked: defaultCauditFieldsCfg.includes('dateDepot') },
        { label: 'Nombre de parts cédées', value: 'nombreTotalPartsVendues', format: 'number', available: [1, 2], checked: defaultCauditFieldsCfg.includes('nombreTotalPartsVendues') },
        { label: 'Prix par part', value: 'prixVenteUnePartSociale', format: 'currency', available: [1, 2], checked: defaultCauditFieldsCfg.includes('prixVenteUnePartSociale') },
        { label: 'Montant de la transaction', value: 'montantTotalVente', format: 'currency', available: [1, 2], checked: defaultCauditFieldsCfg.includes('montantTotalVente') },
        {
            label: 'Nombre total de parts de la société',
            value: 'nombrePartsExistantesTotalSocieteVendue',
            format: 'number',
            available: [1, 2],
            checked: defaultCauditFieldsCfg.includes('nombrePartsExistantesTotalSocieteVendue'),
        },
        { label: "Valeur d'entreprise", value: 'valeurEntreprise', format: 'currency', available: [1, 2], checked: defaultCauditFieldsCfg.includes('valeurEntreprise') },
        { label: 'Valeur des capitaux propres', value: 'valeurCapitauxPropres', format: 'currency', available: [1, 2], checked: defaultCauditFieldsCfg.includes('valeurCapitauxPropres') },
        {
            label: "Multiple – Valeur d’entreprise / Chiffre d'affaires",
            value: 'multipleChiffreAffaires',
            format: null,
            available: [1, 2],
            checked: defaultCauditFieldsCfg.includes('multipleChiffreAffaires'),
        },
        { label: "Multiple – Valeur d'entreprise / Excédent Brut d'Exploitation", value: 'multipleEbe', format: null, available: [1, 2], checked: defaultCauditFieldsCfg.includes('multipleEbe') },
        {
            label: "Multiple – Valeur d'entreprise / Excédent Brut d'Exploitation après impôts*",
            value: 'multipleEbeApresIs',
            format: null,
            available: [1, 2],
            checked: defaultCauditFieldsCfg.includes('multipleEbeApresIs'),
        },
        {
            label: "Multiple – Valeur d'entreprise / EBITDA",
            value: 'multipleEbitda',
            format: null,
            available: [1, 2],
            checked: defaultCauditFieldsCfg.includes('multipleEbitda'),
        },
        {
            label: "Multiple – Valeur d'entreprise / EBITDA après impôts*",
            value: 'multipleEbitdaApresIs',
            format: null,
            available: [1, 2],
            checked: defaultCauditFieldsCfg.includes('multipleEbitdaApresIs'),
        },
        {
            label: "Multiple – Valeur d'entreprise / Résultat d'exploitation",
            value: 'multipleResultatExploitation',
            format: null,
            available: [1, 2],
            checked: defaultCauditFieldsCfg.includes('multipleResultatExploitation'),
        },
        {
            label: "Multiple – Valeur d'entreprise / Résultat d'exploitation après impôts*",
            value: 'multipleResultatExploitationApresIs',
            format: null,
            available: [1, 2],
            checked: defaultCauditFieldsCfg.includes('multipleResultatExploitationApresIs'),
        },
        {
            label: 'Multiple – Valeur des capitaux propres / Résultat net',
            value: 'multipleResultatNet',
            format: null,
            available: [1, 2],
            checked: defaultCauditFieldsCfg.includes('multipleResultatNet'),
        },
    ],
    societe: [
        { label: 'Dénomination', value: 'denomination', format: null, available: [1, 2], checked: defaultCauditFieldsCfg.includes('denomination') },
        { label: 'Société', value: 'denomination', format: null, available: [3], checked: defaultCauditFieldsCfg.includes('denomination') },
        { label: 'Siren', value: 'siren', format: 'number', available: [1, 2, 3], checked: defaultCauditFieldsCfg.includes('siren') },
        { label: 'Date de création', value: 'dateCreationSociete', format: 'date', available: [1, 2, 3], checked: defaultCauditFieldsCfg.includes('dateCreationSociete') },
        { label: 'Forme juridique', value: 'categorieJuridique', format: 'catjur', available: [1, 2, 3], checked: defaultCauditFieldsCfg.includes('categorieJuridique') },
        { label: 'Code NAF', value: 'nafId', format: 'naf', available: [1, 2, 3], checked: defaultCauditFieldsCfg.includes('nafId') },
        { label: 'Activité', value: 'activite', format: null, available: [1, 2], checked: defaultCauditFieldsCfg.includes('activite') },
        { label: 'Activité cédée', value: 'activite', format: null, available: [3], checked: defaultCauditFieldsCfg.includes('activite') },
        { label: 'Adresse', value: 'adresse', format: 'address', available: [1, 2, 3], checked: defaultCauditFieldsCfg.includes('adresse') },
        { label: 'Capital social', value: 'capitalSocialSocieteVendu', format: 'currency', available: [1, 2], checked: defaultCauditFieldsCfg.includes('capitalSocialSocieteVendu') },
        { label: 'Nature du fonds cédé', value: 'etablissementOrigineFonds', format: null, available: [3], checked: defaultCauditFieldsCfg.includes('etablissementOrigineFonds') },
        { label: 'Date de cession', value: 'dateDepot', format: 'date', available: [3], checked: defaultCauditFieldsCfg.includes('dateDepot') },
        {
            label: "Multiple – Fonds de commerce / Chiffre d'affaires",
            value: 'multipleFondsChiffreAffaires',
            format: null,
            available: [3],
            checked: defaultCauditFieldsCfg.includes('multipleFondsChiffreAffaires'),
        },
    ],
    compte: [
        { label: "Chiffre d'affaires", value: 'chiffreAffaires', format: 'currency', available: [1, 2, 3], checked: defaultCauditFieldsCfg.includes('chiffreAffaires') },
        {
            label: 'Autres achats et charges externes',
            value: 'autresAchatsChargesExternes',
            format: 'currency',
            available: [1, 2, 3],
            checked: defaultCauditFieldsCfg.includes('autresAchatsChargesExternes'),
        },
        { label: 'Valeur ajoutée', value: 'autresAchatsChargesExternes', format: 'currency', available: [1, 2, 3], checked: defaultCauditFieldsCfg.includes('valeurAjoutee') },
        { label: 'Charge de personnel', value: 'chargePersonnel', format: 'currency', available: [1, 2, 3], checked: defaultCauditFieldsCfg.includes('chargePersonnel') },
        { label: 'Impôts et taxes', value: 'impotTaxe', format: 'currency', available: [1, 2, 3], checked: defaultCauditFieldsCfg.includes('impotTaxe') },
        { label: "Excédent brut d'exploitation", value: 'ebe', format: 'currency', available: [1, 2, 3], checked: defaultCauditFieldsCfg.includes('ebe') },
        { label: "Excédent brut d'exploitation après impôts*", value: 'ebeApresIs', format: 'currency', available: [1, 2, 3], checked: defaultCauditFieldsCfg.includes('ebeApresIs') },
        { label: "Autres charges d'exploitation", value: 'autresChargesExploitation', format: 'currency', available: [1, 2, 3], checked: defaultCauditFieldsCfg.includes('autresChargesExploitation') },
        {
            label: "Autres produits d'exploitation",
            value: 'autresProduitsExploitation',
            format: 'currency',
            available: [1, 2, 3],
            checked: defaultCauditFieldsCfg.includes('autresProduitsExploitation'),
        },
        {
            label: 'EBITDA',
            value: 'ebitdaEbeAvecAutresProduitsEtChargesExploitation',
            format: 'currency',
            available: [1, 2, 3],
            checked: defaultCauditFieldsCfg.includes('ebitdaEbeAvecAutresProduitsEtChargesExploitation'),
        },
        {
            label: 'EBITDA après impôts*',
            value: 'ebitdaEbeAvecAutresProduitsEtChargesExploitationApresIs',
            format: 'currency',
            available: [1, 2, 3],
            checked: defaultCauditFieldsCfg.includes('ebitdaEbeAvecAutresProduitsEtChargesExploitationApresIs'),
        },
        {
            label: "Dotation amortissement et provision d'exploitation",
            value: 'dotationExploitation',
            format: 'currency',
            available: [1, 2, 3],
            checked: defaultCauditFieldsCfg.includes('dotationExploitation'),
        },
        {
            label: "Reprise amortissement et provision d'exploitation",
            value: 'repriseExploitation',
            format: 'currency',
            available: [1, 2, 3],
            checked: defaultCauditFieldsCfg.includes('repriseExploitation'),
        },
        { label: "Résultat d'exploitation", value: 'resultatExploitation', format: 'currency', available: [1, 2, 3], checked: defaultCauditFieldsCfg.includes('resultatExploitation') },
        {
            label: "Résultat d'exploitation après impôts*",
            value: 'resultatExploitationApresIs',
            format: 'currency',
            available: [1, 2, 3],
            checked: defaultCauditFieldsCfg.includes('resultatExploitationApresIs'),
        },
        { label: 'Charges financières', value: 'chargesFinancieres', format: 'currency', available: [1, 2, 3], checked: defaultCauditFieldsCfg.includes('chargesFinancieres') },
        { label: 'Produits financiers', value: 'produitsFinanciers', format: 'currency', available: [1, 2, 3], checked: defaultCauditFieldsCfg.includes('produitsFinanciers') },
        { label: 'Participation', value: 'participation', format: 'currency', available: [1, 2, 3], checked: defaultCauditFieldsCfg.includes('participation') },
        { label: 'Impôts sur les sociétés', value: 'impotSociete', format: 'currency', available: [1, 2, 3], checked: defaultCauditFieldsCfg.includes('impotSociete') },
        { label: 'Résultat net', value: 'resultatNet', format: 'currency', available: [1, 2, 3], checked: defaultCauditFieldsCfg.includes('resultatNet') },
    ],
    bilan: [
        { label: 'Capitaux propres', value: 'capitauxPropres', format: 'currency', available: [1, 2, 3], checked: defaultCauditFieldsCfg.includes('capitauxPropres') },
        { label: 'Endettement net', value: 'endettementNet', format: 'currency', available: [1, 2, 3], checked: defaultCauditFieldsCfg.includes('endettementNet') },
        { label: 'Stock net', value: 'stockNet', format: 'currency', available: [1, 2, 3], checked: defaultCauditFieldsCfg.includes('stockNet') },
        { label: 'Créances clients nettes', value: 'clientNet', format: 'currency', available: [1, 2, 3], checked: defaultCauditFieldsCfg.includes('clientNet') },
        { label: 'Autres créances nettes', value: 'autresCreancesNet', format: 'currency', available: [1, 2, 3], checked: defaultCauditFieldsCfg.includes('autresCreancesNet') },
        { label: 'Disponibilités nettes', value: 'disponibilite', format: 'currency', available: [1, 2, 3], checked: defaultCauditFieldsCfg.includes('disponibilite') },
        { label: 'Dettes fournisseurs', value: 'detteFournisseur', format: 'currency', available: [1, 2, 3], checked: defaultCauditFieldsCfg.includes('detteFournisseur') },
        { label: 'Autres dettes', value: 'autresDettes', format: 'currency', available: [1, 2, 3], checked: defaultCauditFieldsCfg.includes('autresDettes') },
        {
            label: 'Emprunts et dettes financières',
            value: 'empruntsDettesFinancieres',
            format: 'currency',
            available: [1, 2, 3],
            checked: defaultCauditFieldsCfg.includes('empruntsDettesFinancieres'),
        },
        {
            label: 'Immobilisations incorporelles nettes',
            value: 'immobilisationIncorporelleNette',
            format: 'currency',
            available: [1, 2, 3],
            checked: defaultCauditFieldsCfg.includes('immobilisationIncorporelleNette'),
        },
        {
            label: 'Immobilisation corporelles nettes',
            value: 'immobilisationCorporelleNette',
            format: 'currency',
            available: [1, 2, 3],
            checked: defaultCauditFieldsCfg.includes('immobilisationCorporelleNette'),
        },
        {
            label: 'Immobilisations financières nettes',
            value: 'immobilisationFinanciereNette',
            format: 'currency',
            available: [1, 2, 3],
            checked: defaultCauditFieldsCfg.includes('immobilisationFinanciereNette'),
        },
    ],
};

export const cauditSearchFieldList: { label: string; columns: { header: string; field: string; order: number; formatValue?: Function }[] }[] = [
    {
        label: 'Entreprise',
        columns: [
            { header: 'Activité', order: 1, field: 'activite' },
            { header: 'Adresse', order: 2, field: 'adresse' },
            { header: 'Date de création', order: 3, field: 'dateCreationSociete', formatValue: (value: Date) => value.toLocaleDateString('fr-FR', { timeZone: 'Europe/Paris' }) },
            { header: 'Date de cession', order: 4, field: 'dateDepot', formatValue: (value: Date) => value.toLocaleDateString('fr-FR', { timeZone: 'Europe/Paris' }) },
            { header: 'Catégorie juridique', order: 5, field: 'catJur.label' },
            { header: 'Code NAF', order: 6, field: 'naf.label' },
            { header: 'Code postal', order: 7, field: 'codePostal' },
            { header: 'Commune', order: 8, field: 'commune' },
        ],
    },
    {
        label: 'Transaction',
        columns: [
            { header: 'Capital social de la société', order: 109, field: 'capitalSocialSocieteVendu', formatValue: currencyFormater },
            { header: "Montant de la cession ou de l'apport", order: 110, field: 'montantTotalVente', formatValue: currencyFormater },
            { header: 'Nombre de parts de la transaction', order: 111, field: 'nombreTotalPartsVendues', formatValue: numberFormater },
            {
                header: 'Nombre de parts existantes de la société',
                order: 112,
                field: 'nombrePartsExistantesTotalSocieteVendue',
                formatValue: numberFormater,
            },
            { header: "Prix unitaire de cession ou d'apport", order: 113, field: 'prixVenteUnePartSociale', formatValue: currencyFormater },
            { header: 'Valeur des capitaux propres', order: 114, field: 'valeurCapitauxPropres', formatValue: currencyFormater },
            { header: "Valeur d'entreprise", order: 115, field: 'valeurEntreprise', formatValue: currencyFormater },
            { header: 'Valeur nominale des titres', order: 116, field: 'valeurNominaleTitreSocieteVendu', formatValue: currencyFormater },
        ],
    },
    {
        label: 'Multiple',
        columns: [
            { header: "Valeur d’entreprise / Chiffre d'affaires", order: 217, field: 'multipleChiffreAffaires', formatValue: numberFormater },
            { header: "Valeur d’entreprise / Excédent Brut d'Exploitation", order: 218, field: 'multipleEbe', formatValue: numberFormater },
            { header: "Valeur d’entreprise / Excédent Brut d'Exploitation après IS", order: 219, field: 'multipleEbeApresIs', formatValue: numberFormater },
            { header: 'Valeur d’entreprise / EBITDA', order: 219, field: 'multipleEbitda', formatValue: numberFormater },
            { header: 'Valeur d’entreprise / EBITDA après IS', order: 219, field: 'multipleEbitdaApresIs', formatValue: numberFormater },
            { header: "Valeur d’entreprise / Résultat d'exploitation", order: 220, field: 'multipleResultatExploitation', formatValue: numberFormater },
            { header: "Valeur d’entreprise / Résultat d'exploitation après IS", order: 221, field: 'multipleResultatExploitationApresIs', formatValue: numberFormater },
            { header: 'Valeur d’entreprise / Résultat net', order: 222, field: 'multipleResultatNet', formatValue: numberFormater },
            { header: "Fonds de commerce / Chiffre d'affaires", order: 223, field: 'multipleFondsChiffreAffaires', formatValue: numberFormater },
        ],
    },
    {
        label: 'Compte',
        columns: [
            { header: 'Capitaux propres', order: 323, field: 'capitauxPropres', formatValue: currencyFormater },
            { header: "Chiffre d'affaires", order: 324, field: 'chiffreAffaires', formatValue: currencyFormater },
            { header: "Chiffre d'affaires N-1", order: 325, field: 'chiffreAffairesNm1', formatValue: currencyFormater },
            { header: "Excédent Brut d'Exploitation", order: 326, field: 'ebe', formatValue: currencyFormater },
            { header: "Excédent Brut d'Exploitation après IS", order: 327, field: 'ebeApresIs', formatValue: currencyFormater },
            { header: "Excédent Brut d'Exploitation N-1", order: 328, field: 'ebeNm1', formatValue: currencyFormater },
            { header: "Résultat d'exploitation", order: 329, field: 'resultatExploitation', formatValue: currencyFormater },
            { header: "Résultat d'exploitation après IS", order: 330, field: 'resultatExploitationApresIs', formatValue: currencyFormater },
            { header: "Résultat d'exploitation N-1", order: 331, field: 'resultatExploitationNm1', formatValue: currencyFormater },
            { header: 'Résultat net', order: 332, field: 'resultatNet', formatValue: currencyFormater },
            { header: 'Endettement net', order: 333, field: 'endettementNet', formatValue: currencyFormater },
            { header: "Reprise d'exploitation", order: 334, field: 'repriseExploitation', formatValue: currencyFormater },
            { header: "Reprise d'exploitation N-1", order: 335, field: 'repriseExploitationNm1', formatValue: currencyFormater },
            { header: "Dotation d'exploitation", order: 336, field: 'dotationExploitation', formatValue: currencyFormater },
            { header: "Dotation d'exploitation N-1", order: 337, field: 'dotationExploitationNm1', formatValue: currencyFormater },
        ],
    },
];
