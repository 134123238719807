<script setup lang="ts">
import { useStore } from 'vuex';
import { computed } from 'vue';
import { ACTION_LOGOUT, GETTER_USER } from '@/store/modules/auth';
import { router } from '@/router';
import { fetchApi } from '@/helpers';
import { z } from 'zod';

const store = useStore();
const connectedProfile = computed(() => store.getters[GETTER_USER]);

async function logout() {
    await store.dispatch(ACTION_LOGOUT);
    await router.push({ name: 'signin' });
}

async function openSubscriptionPortal() {
    try {
        const subscriptionResponse = await fetchApi({ url: 'subscription/manage_subscription', method: 'GET', schema: z.object({ url: z.string() }) });
        const { url } = subscriptionResponse;
        window.open(url, '_blank')?.focus();
    } catch (e) {
        console.log(e);
    }
}
</script>

<template>
    <div v-if="connectedProfile" class="mx-auto">
        <div class="relative flex">
            <div class="inset-y-0 right-0 flex w-full flex-col">
                <div class="flex items-center">
                    <fa :icon="['far', 'circle-user']" class="mr-2 size-10 rounded-full text-5xl" />
                    <div class="flex flex-col">
                        <span class="text-lg">{{ connectedProfile.firstname }} {{ connectedProfile.lastname }}</span>
                        <span class="">{{ connectedProfile.email }}</span>
                    </div>
                </div>
                <button class="block px-3 py-2 text-left text-sm" @click="openSubscriptionPortal"><fa class="mr-0.5" :icon="['fas', 'list-check']" /> Gérer mon abonnement</button>
                <button class="block px-3 py-2 text-left text-sm" @click="logout"><fa class="mr-0.5" :icon="['fas', 'user-slash']" /> Déconnexion</button>
            </div>
        </div>
    </div>
</template>
