import {z} from 'zod';

export const productSchema = z.object({
    id: z.number(),
    name: z.string(),
    pricings: z.record(z.string(),z.number()).nullable().optional(),
    description: z.string(),
    product_access: z.array(z.string()),
    position: z.number(),
})

export enum ProductEnum {
    CAUDIMMO = "caudimmo",
    CAUDIT = "caudit"
}

export enum BillingCycleEnum {
    monthly = 'monthly',
    annual = 'annual'
}
