import { z } from 'zod';
import { ProductEnum } from '@/schema/product';

export const enum RoleEnum {
    USER = 'user',
    ADMIN = 'admin',
}

export const profileSchema = z.object({
    id: z.number().readonly(),
    email: z.string(),
    firstname: z.string(),
    lastname: z.string(),
    product_access: z.array(z.nativeEnum(ProductEnum)),
    roles: z.array(z.nativeEnum(RoleEnum)),
});
