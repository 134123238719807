import { createStore, type StoreOptions } from 'vuex';
import auth from '@/store/modules/auth';
import navigation from '@/store/modules/navigation';

export interface RootState {}

const storeOptions: StoreOptions<RootState> = {
    modules: {
        auth,
        navigation,
    },
};

const store = createStore(storeOptions);
export default store;
