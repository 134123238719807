import z from 'zod';
import { profileSchema } from '@/schema/profile';
import type { ActionContext, ActionTree, GetterTree, MutationTree } from 'vuex';
import type { ProductEnum } from '@/schema/product';
import { fetchApi } from '@/helpers';
import type { RootState } from '@/store/store';

export const LOCAL_STORAGE_JWT_KEY = 'token';

export const EVENT_AUTH_SUCCESS = 'auth_success';
export const EVENT_AUTH_ERROR = 'auth_error';

export const ACTION_FETCH_USER_PROFILE = 'fetchUserProfile';
export const ACTION_LOGIN = 'login';
export const ACTION_LOGOUT = 'logout';
export const ACTION_INITIALIZE_AUTH = 'initializeAuth';

const MUTATION_AUTH_REQUEST = 'auth_request';
const MUTATION_AUTH_SUCCESS = 'auth_success';
const MUTATION_AUTH_ERROR = 'auth_error';
const MUTATION_SET_USER = 'set_user';
const MUTATION_LOGOUT = 'logout';

export const GETTER_IS_AUTHENTICATED = 'isAuthenticated';
export const GETTER_AUTH_STATUS = 'authStatus';
export const GETTER_USER = 'user';
export const GETTER_HAS_ACCESS_TO_PRODUCT = 'hasAccessToProduct';

export const STATUS_AUTH_LOADING = 'loading';
export const STATUS_AUTH_SUCCESS = 'success';
export const STATUS_AUTH_ERROR = 'error';

interface LoginInput {
    username: string;
    password: string;
}

interface AuthState {
    jwt: string | null;
    user: z.infer<typeof profileSchema> | null;
    status: string;
}

const state: AuthState = {
    jwt: localStorage.getItem(LOCAL_STORAGE_JWT_KEY),
    user: null,
    status: '',
};

const getters: GetterTree<AuthState, RootState> = {
    [GETTER_IS_AUTHENTICATED]: (s) => !!s.jwt,
    [GETTER_AUTH_STATUS]: (s) => s.status,
    [GETTER_USER]: (s) => s.user,
    [GETTER_HAS_ACCESS_TO_PRODUCT]: (s) => (product: ProductEnum) => {
        if (!s.user) {
            return false;
        }
        return s.user.product_access.includes(product);
    },
};

const actions: ActionTree<AuthState, RootState> = {
    async [ACTION_INITIALIZE_AUTH]({ commit, dispatch }: ActionContext<AuthState, RootState>) {
        const jwt = localStorage.getItem(LOCAL_STORAGE_JWT_KEY);

        if (!localStorage.getItem(LOCAL_STORAGE_JWT_KEY)) {
            return;
        }
        commit(EVENT_AUTH_SUCCESS, jwt);
        try {
            await dispatch(ACTION_FETCH_USER_PROFILE);
        } catch (error) {
            commit(EVENT_AUTH_ERROR);
            localStorage.removeItem(LOCAL_STORAGE_JWT_KEY);
        }
    },
    async [ACTION_LOGIN]({ commit, dispatch }: ActionContext<AuthState, RootState>, loginInput: LoginInput) {
        commit(MUTATION_AUTH_REQUEST);
        try {
            const { access_token: jwt } = await fetchApi({ url: 'login', method: 'POST', body: loginInput, schema: z.object({ access_token: z.string() }) });
            localStorage.setItem(LOCAL_STORAGE_JWT_KEY, jwt);
            commit(EVENT_AUTH_SUCCESS, jwt);
            await dispatch(ACTION_FETCH_USER_PROFILE);
        } catch (error) {
            commit(EVENT_AUTH_ERROR);
            localStorage.removeItem(LOCAL_STORAGE_JWT_KEY);
            console.error('Login failed:', error);
            throw error;
        }
    },

    async [ACTION_FETCH_USER_PROFILE]({ commit }: ActionContext<AuthState, RootState>) {
        try {
            const user = await fetchApi({ url: 'profile', method: 'GET', schema: profileSchema });
            commit(MUTATION_SET_USER, user);
        } catch (error) {
            commit(EVENT_AUTH_ERROR);
            localStorage.removeItem(LOCAL_STORAGE_JWT_KEY);
            throw error;
        }
    },

    [ACTION_LOGOUT]({ commit }: ActionContext<AuthState, RootState>) {
        return new Promise<void>((resolve) => {
            commit(MUTATION_LOGOUT);
            localStorage.removeItem(LOCAL_STORAGE_JWT_KEY);
            resolve();
        });
    },
};

const mutations: MutationTree<AuthState> = {
    [MUTATION_AUTH_REQUEST](s) {
        s.status = STATUS_AUTH_LOADING;
    },
    [MUTATION_AUTH_SUCCESS](s, jwt: string) {
        s.status = STATUS_AUTH_SUCCESS;
        s.jwt = jwt;
    },
    [MUTATION_AUTH_ERROR](s) {
        s.status = STATUS_AUTH_ERROR;
    },
    [MUTATION_SET_USER](s, user: z.infer<typeof profileSchema>) {
        s.user = user;
    },
    [MUTATION_LOGOUT](s) {
        s.status = '';
        s.jwt = null;
        s.user = null;
    },
};

export default {
    state,
    getters,
    actions,
    mutations,
};
